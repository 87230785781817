
import Vue from "vue";
import ProfileData from "@/components/Profile/ProfileData.vue";
import { mapGetters } from "vuex";
import Rating from "@/components/Ratings/Rating.vue";
import ProfileDataLoading from "@/components/Profile/ProfileDataLoading.vue";
import RatingLoading from "@/components/Ratings/RatingLoading.vue";

export default Vue.extend({
  name: "RatingsPage",
  components: {
    ProfileData,
    Rating,
    ProfileDataLoading,
    RatingLoading
  },
  data() {
    return {
      ratingTabs: [
        {
          name: "Recent",
          type: "recent"
        },
        {
          name: "Mentorcasts",
          type: "mentorcast"
        },
        {
          name: "Sessions",
          type: "session"
        },
        {
          name: "Programs",
          type: "program"
        },
        {
          name: "Given",
          type: "given"
        }
      ],
      currentTab: "recent",
      data: [] as any,
      page: 1,
      load_more: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      ratings: "profile/getRatings"
    })
  },
  mounted() {
    this.loading = true;
    this.getRatings();
  },
  methods: {
    onLoadMore() {
      this.page++;
      this.getRatings(this.page);
    },
    getRatings(page = 1) {
      this.$store
        .dispatch("profile/GET_RATINGS", {
          tab: this.currentTab,
          page
        })
        .then(data => {
          this.loading = false;
          this.page = page;
          this.load_more = page < data.last_page;
          this.data.push(...data.data);
        });
    },
    onTab(tab: any) {
      this.load_more = false;
      this.page = 1;
      this.data = [];
      this.currentTab = tab;
      this.getRatings();
    }
  }
});
